<template>
  <div aria-live="assertive" class="notification__container">
    <div class="notification__wrapper">
      <transition
        enter-active-class="notification__enter-active"
        enter-from-class="notification__enter-from"
        enter-to-class="notification__enter-to"
        leave-active-class="notification__leave-active"
        leave-from-class="notification__leave-from"
        leave-to-class="notification__leave-to"
      >
        <div v-if="toast.isShown" class="notification__main notification__main--toast">
          <LoaderIcon class="notification__icon notification__icon--toast"/>
          <div class="notification__message notification__message--toast">
            <p>
              {{ toast.message ? toast.message : "Please wait..." }}
            </p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon, XIcon } from "@heroicons/vue/outline";
import LoaderIcon from "@/assets/icons/LoaderIcon"

export default {
  props: ["toast"],
  components: {
    ExclamationIcon,
    XIcon,
    LoaderIcon
  },
  created(){
    /** 
     * Toast will be closed in 5 seconds. Temporary use for testing.
     * Toast's lifespan should be set by the parent. For example, when an 
     * API call is being made and result is received, then close the toast.
     * Please uncomment function below only when testing.
     */
    setTimeout(() => {
      this.toast.isShown = false
    }, 5000);
  }
};
</script>
<style scoped>
  @import './notification.css';
</style>