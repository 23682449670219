<template>
  <div aria-live="assertive" class="notification__container">
    <div class="notification__wrapper">
      <transition
        enter-active-class="notification__enter-active"
        enter-from-class="notification__enter-from"
        enter-to-class="notification__enter-to"
        leave-active-class="notification__leave-active"
        leave-from-class="notification__leave-from"
        leave-to-class="notification__leave-to"
      >
        <div
          v-if="notification.isShown"
          class="notification__main notification__main--default"
        >  
            <ExclamationIcon v-if="notification.type=='error'"
              class="notification__icon notification__icon--error"
              aria-hidden="true"
            />
            <CheckCircleIcon v-else-if="notification.type=='success'"
              class="notification__icon notification__icon--success"
              aria-hidden="true"
            />
            <div class="notification__message notification__message--default">
              <p class="notification__message__title">
                {{ notification.title }}
              </p>
              <p class="notification__message__details">
                {{ notification.details }}
              </p>
              <button
                @click="notification.isShown = false"
                class="notification__button--dismiss"
              >
                Dismiss
              </button>
            </div>
            <div class="ml-4 flex-shrink-0 flex">
              <button
                @click="notification.isShown = false"
                class="notification__button--close"
              >
                <span class="sr-only">Close</span>
                <XIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon, CheckCircleIcon, XIcon } from "@heroicons/vue/outline";

export default {
  props: ["notification"],
  components: {
    ExclamationIcon,
    CheckCircleIcon,
    XIcon,
  },
  created(){
    /** 
     * Notification will be closed in 5 seconds. Temporary use for testing.
     * Notification's lifespan should be set by the parent. For example, when an 
     * API call is being made and result is received, then close the notification.
     * Please uncomment function below only when testing.
     */
    setTimeout(() => {
      this.notification.isShown = false
    }, 5000);
  }
};
</script>
<style scoped>
  @import './notification.css';
</style>